import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "How Mentoring Impacted Me",
  "author": "Tony",
  "date": "2020-01-30T08:33:54.000Z",
  "categories": ["mentorship", "Personal-Professional Growth"],
  "slug": "how-mentoring-impacted-me",
  "draft": false,
  "meta_title": "How Mentoring Impacted Me",
  "cover": "../../images/wp_blog_images/unnamed-1.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Why I am Passionate about Mentoring`}</strong></p>
    <p>{`Beginning with my first duty assignment as an Army Second Lieutenant, to my time in the Private Sector as an Executive Leader, and today as a Start-up Co-founder the process of mentoring has been key to my success as a leader. I have had five distinct mentors over the years and each has contributed to my leadership vertical development.`}</p>
    <p><strong parentName="p">{`Mentoring vs. Coaching`}</strong></p>
    <p>{`The terms mentoring and coaching are often construed to mean the same thing. Nothing could be further from the truth. A simple way to understand the difference between mentoring and coaching is: A coach has some great questions for your answers; a mentor has some great answers to your questions (Figure 1). Each of my mentors 1) possessed a great deal of knowledge and business unit know-how, 2) provided me advice and guidance, and 3) shared their experiences with me. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "921px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "61.5625%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAABJ0AAASdAHeZh94AAACTUlEQVQoz3WS228SQRjF+X989sXEJ029pPHBBFNrrPpgYmyiJmqtxjSamLbEW+slFts0tGCtpE0NyzblWhbYS7csCywuCytli8JSCWAvgFxm1sBSfWh6Mg8zk/l958zMp1HaghACCNU5gLBSa0hyiWDjvgAvZYr1BgAAgiZoDwj3T2qUfQEIf/2uFndr6rK6XcAJxksylb2icog0apXdal1n5np12OXnXpMnxeSKk7QwTIsILSBo0LjAf7EkM6lChNxAjetxNtMKC6CmCVo0Qm2eeWy/9YHsf0ueeuY4avQe+4xf8XB9o86u82btdcvNB/aBXn3/2fGBix9XLeFW0iboOG+VqoMGVjvi69Hh40gMEbOv/Nz9ZdxgD1hQbmIm8H56LYiLzqXA9Asr4xNaMIAapU3nt3IUzcwjXrMVi0a/SWIizDC4z0dRZIQL8wKfEBPpdFJKiTGBz8ryvzu34PSPdDjCiokoHwuxbCDChag13OV2OG02miAYAg/Q9ALqmV10eLx4Ts6oP9SJXZDL9rkoZdv4GS+tuLKTc0n7atpNxl+GJGMyT2zX9a5Uz6hfO+J/OBPOlf60WUUD2w+WjMpD12ZvnB7v7xobfGTr7ls83v3p6l1EizInzcQRg+vEa/edd9TtCercE+cSISmK0gSwEzsj5U1vVpbnCW7t+1drZEyPTRncVgd9z4INWTA0tjHlEC7psAvD7qemYHFn3/mwBmjU9/D10DodapR31Z3CTlUulpsA/G+Sg5iapbRTwWjezwh71Zrqc7DD/gIaAVav/NHKXQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "unnamed 1",
          "title": "unnamed 1",
          "src": "/static/e5b3efa6c91b67a0ac4eb37d3d053e23/0f67e/unnamed-1.png",
          "srcSet": ["/static/e5b3efa6c91b67a0ac4eb37d3d053e23/72799/unnamed-1.png 320w", "/static/e5b3efa6c91b67a0ac4eb37d3d053e23/6af66/unnamed-1.png 640w", "/static/e5b3efa6c91b67a0ac4eb37d3d053e23/0f67e/unnamed-1.png 921w"],
          "sizes": "(max-width: 921px) 100vw, 921px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{` Figure 1, The Differences Between Coaching, Mentoring and Related Fields. Culture at Work, (Wilson, C., 2016)`}</p>
    <p><strong parentName="p">{`Vertical Development and Mental Growth`}</strong></p>
    <p>{`All my mentors contributed to my `}<em parentName="p">{`horizontal development,`}</em>{` increasing my knowledge, skills, and competencies. However, my mentors spent most of their time attending to my `}<em parentName="p">{`vertical development`}</em>{`, enhancing my ability to `}<em parentName="p">{`think`}</em>{` and `}<em parentName="p">{`act`}</em>{` in complex systems.`}</p>
    <p>{`They did this by `}</p>
    <p>{`1) Providing me valuable new ways of `}<em parentName="p">{`thinking`}</em>{` in volatile, uncertain and ambiguous situations`}</p>
    <p>{`2) Drawing me out of my comfort zone through intense stretch assignments `}</p>
    <p>{`3) Helping me create strong developmental networks at work`}</p>
    <p>{`4) Accepting my failures when they led to my mental growth.`}</p>
    <p>{`In the end, my mentors were focused on my vertical growth trajectory, which was about my future, maturity, growing up and increasing my depth. As I look back to each of my mentoring experiences, I now know my mentors were all focused on my adult mental growth and my transformation as a leader.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      